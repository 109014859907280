import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "check" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "primary-image-fields"
    // var lngth = document.querySelectorAll('.primary-image-fields').length
    var lngth = this.checkTarget.querySelectorAll('.primary-image .primary-image-fields').length
    if ( lngth == 1 || lngth >= 1) {
      this.linksTarget.classList.add('dn')
    } else {
      this.linksTarget.classList.remove('dn')
    }
    console.log(this.wrapperClass, lngth)
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
    this.linksTarget.classList.add('dn')
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }

    this.linksTarget.classList.remove('dn')
  }
}
