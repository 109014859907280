import { Controller } from 'stimulus';
import 'select2';
import 'script-loader!select2/dist/js/select2.js';
import 'select2/dist/css/select2.css';

export default class extends Controller {
  connect() {
    $(() => {
      $('.select2').select2({
         minimumResultsForSearch: Infinity,
      });
    });
  }
}
