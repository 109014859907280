import { Controller } from 'stimulus';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded'

export default class extends Controller {

  connect() {
    var elem = document.querySelector('.masonry-grid');
    var msnry = new Masonry( elem, {
      itemSelector: '.grid-item',
    });
    imagesLoaded(elem).on( 'progress', function() {
      msnry.layout();
    });
  }
}
