import { Controller } from 'stimulus';
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
require('flickity-imagesloaded');
// import imagesLoaded from 'imagesloaded'

export default class extends Controller {
  connect() {
    var elem = document.querySelector('.slide');
    var flkty = new Flickity( elem, {
      cellAlign: 'center',
      imagesLoaded: true,
      prevNextButtons: false,
      percentPosition: true,
      pageDots: true,
      cellSelector: '.slide-cell',
      contain: true,
      resize: true
    });
  }
}
