import { Controller } from 'stimulus';
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
require('flickity-imagesloaded');
// import imagesLoaded from 'imagesloaded'

export default class extends Controller {
  connect() {
    var elem = document.querySelector('.carousel');
    var flkty = new Flickity( elem, {
      cellAlign: 'left',
      imagesLoaded: true,
      prevNextButtons: false,
      percentPosition: true,
      pageDots: false,
      bgLazyLoad: true,
      cellSelector: '.carousel-slide',
      contain: true,
      resize: true
    });
  }
}
