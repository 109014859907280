// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("tachyons")
require("trix")
require("@rails/actiontext")
require("select2")
require("vendor/filterrific")
require("photoswipe")
require('photoswipe/dist/default-skin/default-skin')

import "controllers"
import "@fortawesome/fontawesome-free/js/all"

// document.addEventListener('turbolinks:load', () => {
// });
